import React, { useState } from "react";
import styles from './UserGuide.module.css'
import {NavigationUl,Payment, Principle, Operation} from './components'

export const UserGuide:React.FC = () => {
    const [value,setValue] = useState('0');
    const [title,setTitle] = useState('Method of collecting test coins');
    return (
        <div className={styles['user-box']}>
            <div className={styles['page-container']}>
                <h1 className={styles.title}>{title}</h1>
                {
                    value === '0' ? <Payment/> :(value === '1' ? <Principle /> : <Operation />)
                }
                
            </div>
            
            <div className={styles['page-right']}>
                <NavigationUl 
                    handleClick={(code,name) => {
                        setValue(code);
                        setTitle(name);
                    }}
                />
            </div>
        </div>
    )
}