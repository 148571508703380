import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import style from "./index.module.css";
import { Header , Footer} from "../components";
import { Home, Create, PoolList, Portolio, Join, UserGuide } from "../page";

export default function RouterView() {
  return (
    <Router>
      <div style={{position:'sticky',top:'0',zIndex:'3'}}>
        <Header />
      </div>
      <div className={style["page-container"]}>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div>loading</div>}>
                <Home />
              </Suspense>
            }
          ></Route>
          <Route
            path="/PoolList"
            element={
              <Suspense fallback={<div>loading</div>}>
                <PoolList />
              </Suspense>
            }
          ></Route>
          <Route
            path="/Create"
            element={
              <Suspense fallback={<div>loading</div>}>
                <Create />
              </Suspense>
            }
          ></Route>
          <Route
            path="/Portolio"
            element={
              <Suspense fallback={<div>loading</div>}>
                <Portolio />
              </Suspense>
            }
          ></Route>
          <Route
            path="/Join"
            element={
              <Suspense fallback={<div>loading</div>}>
                <Join />
              </Suspense>
            }
          ></Route>
          <Route
            path="/UserGuide"
            element={
              <Suspense fallback={<div>loading</div>}>
                <UserGuide />
              </Suspense>
            }
          ></Route>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
