/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { 
    // ConnectionProvider, WalletProvider, 
    useWallet } from '@solana/wallet-adapter-react';
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import { 
    // WalletModalProvider,
    WalletMultiButton} from '@solana/wallet-adapter-react-ui';
import "@solana/wallet-adapter-react-ui/styles.css";
// import { clusterApiUrl } from '@solana/web3.js';

// import {SolongWalletAdapter} from '@solana/wallet-adapter-solong'
// import {PhantomWalletAdapter} from '@solana/wallet-adapter-phantom';
import store from "../../redux/store";
import {changePublickeyActionCreator} from '../../redux/publicKey/publickActions'

export const WalletBtn:React.FC = () => {
    // const network = WalletAdapterNetwork.Devnet;
    // const endpoint =  clusterApiUrl(network);
    // const wallets =[
    //     // new SolongWalletAdapter(),
    //     new PhantomWalletAdapter(),
    // ];
    // const timer:any = useRef(null);
    const BtnComponent = () => {
        const { connected, publicKey } = useWallet();
        useEffect(() => {
            const handleConnect = () => {
                console.log('Connected with account:', publicKey?.toBase58());
                const pub = !publicKey ? '' : publicKey.toBase58();
                const action = changePublickeyActionCreator(pub)
                store.dispatch(action) 
                // }
            //     // 执行连接后的逻辑
            //     // setTimeout(() => {
            //     //     window.location.reload();
                    
            //     // }, 1000);
            //     console.log(executed);
            };
            
            if (connected) {
                // 已连接
                // setExecuted(true);
                handleConnect()
            }else {
                // setExecuted(false);
                handleConnect()
            }
            return () => {
                // 清理
                // clearTimeout(timer)
            };
        }, [connected, publicKey]);
        
        // useEffect(() => {
        //     if(executed) {
        //         console.log('链接了');
        //         setTimeout(() => {
        //             window.location.reload();
        //         }, 1000);
        //     }
        // },[executed])
        return (
            <div>
                <WalletMultiButton />
            </div>
        );
    }
    return (
        <>
        {/* <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider> */}
                    <BtnComponent />
                    {/* <WalletMultiButton /> */}
                {/* </WalletModalProvider>
            </WalletProvider>
         </ConnectionProvider> */}
        </>
    )
}
  
// const DisplayPublicKey: React.FC = () => {
//     const { publicKey } = useWallet();
    // const pub = !publicKey ? '' : publicKey.toBase58();
    // const action = changePublickeyActionCreator(pub)
    // store.dispatch(action)
//     return <></>;
// };