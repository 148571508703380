import axios from 'axios';
import { BN } from "@project-serum/anchor";
import { message } from 'antd';
import { Connection, PublicKey} from "@solana/web3.js";

// 链接solana主网地址
export const connection = new Connection('https://radial-nameless-county.solana-devnet.quiknode.pro/6bebb9ec55f87563cda3b23166b175e7fa3e2727/');

export const programId = new PublicKey("FXdkYrxybRLydDdRFpyzMXwzJTCBc78UrNVX36pDPS9j");
// idl文件
export const IDL = require("../idl/mts_pool.json");

// 获取错误消息
export const getErrorInfo = (code: Number) =>{
  const errors = IDL['errors'];
  let msg = ''
  errors.forEach((it: any) => {
    if(it.code === code) {
      msg = it.msg
    }
  })
  return msg
}
// 获取币价格的接口
export const getUsdtPrice = async(types) => {
    console.log(types);
    interface ApiResponse {}
    const apiUrl = `https://api.dexscreener.com/latest/dex/search?q=${types}%20USDC`;
    try {
      const response:any = await axios.get<ApiResponse>(apiUrl);
      console.log(response.data);
      const resPriceUsd = response?.data?.pairs[0].priceUsd;
      return resPriceUsd
    } catch (error) {
      console.error('Error fetching data:', error);
    }
} 

export const createTokenAcount = async(publicKey,mintToken) => {
  console.log('-------创建token acount ----:',publicKey);
  let creatorTokenAccount:any = []
  // 使用用户的钱包pubkey作为值创建creator_token_account
  const OWN = new PublicKey(publicKey);

  const TOKEN_PROGRAM_ID = new PublicKey(
    "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
  );
  const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey(
    "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
  );
  // const creatorTokenAccount = new PublicKey(publicKey);
  try {
    creatorTokenAccount = await PublicKey.findProgramAddressSync(
      [OWN.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), new PublicKey(mintToken).toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    );
  }catch(error:any) {
    // console.log(error);
    message.error('Invalid public key input')
    creatorTokenAccount = []
  }
  
  const creatorTokenAccountBase58 =creatorTokenAccount.length === 0 ? '' : new PublicKey(
    creatorTokenAccount[0].toString()
  ).toBase58();
  console.log('creatorTokenAccountBase58:',creatorTokenAccountBase58);
  
  return creatorTokenAccountBase58// new PublicKey('D2ZecaRk95tu89tS21FNfAejVqs21scenZJVwLwyFqe2')
}

// 将浮点数转为精度为9的整数
export const FloatToBn = (e, decimals = 9) => {
    const floatValue = parseFloat(e);
    if (!isNaN(floatValue)) {
      // 将浮点数转换为整数字符串（乘以 10^decimals 后取整）
      // const decimals = 9; // 设置精度
      const integerValue = Math.round(floatValue * Math.pow(10, decimals)).toString();
      // 使用整数字符串创建 BigNumber 对象
      const bn = new BN(integerValue);
      return bn
    }
};

// 将账户显示为...形式
export const base58Slice = (str) => {
  const base58 = new PublicKey(str).toBase58();
  return base58.slice(0, 4) + '...' + base58.slice(-4);
}

// 将时间戳转为年月日时分秒
export const Time = (str) => {
  var date = new Date(parseInt(str));  // 参数需要毫秒数，所以这里将秒数乘于 1000
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  const D = date.getDate() + ' ';
  const h = date.getHours() + ':';
  const m = date.getMinutes() + ':';
  const s = date.getSeconds(); 
  return Y+M+D+h+m+s
}