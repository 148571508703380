import React,{useEffect, useState} from 'react';
import { Modal,Input,message } from 'antd';
import style from './customModal.module.css'

interface ModalProps {
  param:any;
  startPrice: string;
  visible: boolean;
  riceUsd:string;
  onOk: (value:string,riceUsd:string) => void;
  onClose: () => void;
}

const CustomModal: React.FC<ModalProps> = ({param,startPrice, visible, riceUsd, onClose,onOk }) => {
  console.log('param----',param);
  
  const [inputValue, setInputValue] = useState('');
  const [joinAmount, setJoinAmount] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  // const [riceUsd, setPriceUsd] = useState('')
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) =>{
    // 输入大于0的整数
    const value = e.target.value.replace(/\D|^0/g, '');
    setInputValue(value)
    const amount = isNaN((parseInt(param.addUnit)/1000000000) * parseFloat(value)) ? '0' : ((parseInt(param.addUnit)/1000000000) * parseFloat(value)).toFixed(2)
    setJoinAmount(amount)
  }
  const handleOk = () => {
    if(!inputValue) return message.error('The input box is not empty');
    setIsLoading(true);
    onOk(joinAmount,riceUsd);
  }
  useEffect(() => {
    if(!visible) {
      setInputValue('')
      setIsLoading(false);
    }
  },[visible])
  return (
    <Modal
      title="Join Pool"
      confirmLoading={isLoading}
      destroyOnClose
      open={visible}
      onOk={handleOk}
      onCancel={onClose}
      okButtonProps={{style:{background:'#512da8'}}}
    >
      <div className={style['pool-box']}>
        <div>
          <div className={style['text']}>
            <span>Join Layer:</span>
            <span>{param.Curent_Layer}</span>
          </div>
          <div className={style['text']}>
            <span>Layer Open Price:</span>
            <span>{startPrice}</span>
          </div>
          
        </div>
        <div>
          <div className={style['text']}>
            <span>Join Price:</span>
            <span>${riceUsd}</span>
          </div>
          <div className={style['text']}>
            <span>Base Unit:</span>
            <span>{parseInt(param.addUnit)/1000000000} </span>
          </div>
        </div>
        <div>
          <div className={style['text']}>
            <span>Join Amount:</span>
            <span>{joinAmount}</span>
          </div>
        </div>
       
        <div className={style['input']}>
           <span>Unit Amount:</span>
           <Input value={inputValue} onChange={handleChange}/>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;