import React from 'react'
import ReactEcharts from "echarts-for-react"
// import echarts from "echarts";

export default function Home() {
  const getOption = () => {
    let option = {
        legend: {
            data: ['Avg Price', 'Spot Price']
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
            name:'Avg Price',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            stack: 'Total',
            smooth: true
            },
            {
            name:'Spot Price',
            data: [77, 332, 901, 45, 1290, 1330, 1600],
            type: 'line',
            stack: 'Total',
            smooth: true
            },
        ]
    };
    return option;
  };
  return <ReactEcharts option={getOption()} />;
}
