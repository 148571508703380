import React from "react";
import styles from './Footer.module.css';
export const Footer:React.FC = () => {
    return (
      <div>
        <div className={styles['footer-bq']}>
          <div className={styles['icon-logo-dis']} onClick={() => window.open('https://discord.gg/xsZ4rsrWv2')}></div>
          <div className={styles['icon-logo-twi']} onClick={() => window.open('https://twitter.com/slingfi2024')}></div>
          <span> Copyright @ slingfi Team - Visitor: 1024</span>
        </div>
        
      </div>
    )
}