
export const marksWaller = {
    0: "0%",
    25: "25%",
    50: "50%",
    75: "75%",
    100: "100%",
  };
export  const marksDouble = {
    2: "2x",
    3: "3x",
    4: "4x",
    5: "5x",
  };
