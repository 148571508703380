/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./Portolio.module.css";
import CustomTable, { PoolData } from "./tables/index";
// import { useWallet } from "@solana/wallet-adapter-react";
import { useQuery } from "@apollo/client";
import { GET_User_Pdas } from "../../lib/joinApi";
import { PublicKey } from "@solana/web3.js";
import { Program, setProvider, AnchorProvider } from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Spin, message, Modal } from "antd";
import { useSelector } from "../../redux/hooks";
import {
  connection,
  programId,
  IDL,
  getErrorInfo,
  createTokenAcount,
} from "../../lib/Common";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

interface PortolioProps {
    mtsPool?: string
}

export const JoinedPool: React.FC<PortolioProps> = (mtsPool) => {
console.log('--------0----', mtsPool);

  const mts_pool_account = mtsPool.mtsPool || '';
  window.Buffer = window.Buffer || require("buffer").Buffer;
  // const {publicKey} = useWallet();
  const publicKey = useSelector((state) => state.publicKey);
  const [spinning, setSpinning] = useState(true);
  const [arrLength, setArrLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setrecord] = useState<any>({});

  const [poolDataState, setPoolDateState] = useState<PoolData[]>([]);

  const anchorWallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, anchorWallet as any, {});
  setProvider(provider);
  // 1.生成一个新的密钥对 2.获取智能合约公钥
  const program = new Program(IDL, programId);
  console.log("publicKey-------", publicKey);

  const queyUserPdas: any = useQuery(GET_User_Pdas, {
    variables: { publicKey, mts_pool_account},
  });
  //赎回
  const handleButtonClick = async (row: any) => {
    setrecord({ ...row });
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    console.log("-----", record);

    try {
      await redeemCommon();
      setVisible(false);
      setConfirmLoading(false);
      message.info("Redemption successful");
    } catch (err: any) {
      setConfirmLoading(false);
      if (err?.InstructionError && err?.InstructionError.length) {
        message.error(getErrorInfo(err?.InstructionError[1]?.Custom));
      } else {
        message.error(err.toString());
      }
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (poolDataState.length === arrLength) {
      setSpinning(false);
    }
  }, [poolDataState.length]);

  async function redeemCommon() {
    const create_token_acount = await createTokenAcount(
      publicKey,
      record.mintToken
    );
    const [poolTokenAccountPda] = await PublicKey.findProgramAddressSync(
      [
        Buffer.from("zmts-pool"),
        new PublicKey(record.mts_pool_account).toBuffer(),
      ],
      programId
    );
    const latestBlockhash = await connection.getLatestBlockhash("finalized");
    const poolFeeAccount = new PublicKey(
      "7QAtUaP7ZZ4V6W9D5yoJoPaRA2wd8zZjWaYastoXDBMc"
    );

    const redeem_context = {
      user: new PublicKey(publicKey),
      mtsPool: record.mts_pool_account,
      layerUserPda: new PublicKey(record.user_pda),
      mintToken: record.mintToken,
      poolTokenAccount: poolTokenAccountPda.toString(),
      userTokenAccount: create_token_acount,
      feeTokenAccount: poolFeeAccount,
      layerAccount: record.layer_pda,
      tokenProgram: TOKEN_PROGRAM_ID.toBase58(),
    };
    for (let k in redeem_context) {
      console.log("当前数量9", k + ":" + redeem_context[k].toString());
    }

    const redeem_tx = await program.methods
      .redeemToken()
      .accounts(redeem_context)
      .rpc({ skipPreflight: true });
    await connection.confirmTransaction({
      signature: redeem_tx,
      blockhash: latestBlockhash.blockhash,
      lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
    });
  }

  function newDataFun(el, mtspoolJson, layerJson, userJson) {
    console.log(el);
    let pool_state = "";
    let layerPda = "";
    // let layerNo = 0;
    console.log("------mtspoolJson----", mtspoolJson.toString() !== "{}");

    if (mtspoolJson.toString() !== "{}") {
      for (let k in mtspoolJson.poolStatus) {
        pool_state = k;
      }
      for (let k in layerJson) {
        console.log(k + ":" + parseFloat(layerJson[k]));
      }
      // mtspoolJson.layerPdas.map(ite => {
      //     ite.layerPda = ite.layerPda.toString()
      //     return ite
      // })
      console.log("mtspoolJson.layerPdas----", mtspoolJson.layerPdas);

      layerPda =
        !mtspoolJson?.layerPdas || mtspoolJson.layerPdas.length === 0
          ? ""
          : mtspoolJson.layerPdas[mtspoolJson.layerPdas.length - 1].layerPda;
      // layerNo =!mtspoolJson?.layerPdas || mtspoolJson.layerPdas.length === 0 ? '' : mtspoolJson.layerPdas[mtspoolJson.layerPdas.length - 1].layerNo            ;
      console.log(pool_state === "investing" && el.layer_pda !== layerPda);
      console.log(el.layer_pda);
      console.log(layerPda.toString());
    }

    const obj: PoolData = {
      id: !el.user_pda ? "--" : el.user_pda,
      poolAccount: !el.mts_pool_account ? "--" : el.mts_pool_account,
      poolSymbol: !el.mint_token_name ? "" : el.mint_token_name,
      status: pool_state,
      layerNo: !layerJson.layerNo ? "--" : layerJson.layerNo,
      /**Redeemable: 如果Pool Status为0（Investing）且Layer No不是最后一层时，Redeemable为true；如果Pool Status为2（Settled）时，Redeemable为true；如果Pool Status为1（Stop Profit）时，Redeemable为false；如果Pool Status为0（Investing）且Layer No是最后一层时，Redeemable为false */
      redeemable: el.redeem_state, //(pool_state === 'investing' && el.layer_pda !== layerPda) || pool_state === 'settled' ? 'true' :'false',
      joinedAmount: !userJson.joinAmount
        ? "--"
        : (parseFloat(userJson.joinAmount) / Math.pow(10, 9)).toString(),
      redeemAmount: (
        parseFloat(userJson.allocedProfitAmt) +
        parseFloat(userJson.settledCostAmt)
      ).toString(),
    };
    let objPlus = { ...obj, ...userJson, ...el, ...layerJson, ...mtspoolJson };
    console.log("-----------数据-----", objPlus);
    setPoolDateState((old) => [...old, objPlus]);
  }

  useEffect(() => {
    setSpinning(true);
    if (queyUserPdas?.data?.getUserPdas.length === 0) {
      setSpinning(false);
      setPoolDateState([]);
    }
    const fetchData = async (el, acount, layerpda, userpda) => {
      let mtspool = {},
        layer = {},
        user = {};
      try {
        mtspool = await program.account.mtsPoolData.fetch(acount);
        layer = await program.account.mtsPoolLayerAccount.fetch(layerpda);
        user = await program.account.mtsLayerUserData.fetch(userpda);
      } catch (err) {}

      newDataFun(el, mtspool, layer, user);
    };
    console.log("*********getUserPdas-------", queyUserPdas);

    if (queyUserPdas?.data?.getUserPdas) {
      setArrLength(queyUserPdas?.data?.getUserPdas.length);

      // console.log('******queyUserPdas',queyUserPdas?.data?.getUserPdas);
      queyUserPdas?.data?.getUserPdas.forEach(async (el) => {
        // console.log(el.layer_pda);

        const acount = new PublicKey(el.mts_pool_account);
        const layerpda = new PublicKey(el.layer_pda);
        const userpda = new PublicKey(el.user_pda);

        fetchData(el, acount, layerpda, userpda);
      });
    }
  }, [queyUserPdas]);

  return (
    // <Header></Header>
    <div className={styles["page-container"]}>
      <Spin spinning={!publicKey ? false : spinning} tip="Loading...">
        <CustomTable
          key="poollist"
          data={poolDataState}
          type="joinedPool"
          onButtonClick={handleButtonClick}
        />
      </Spin>
      <Modal
        title="Tips"
        centered
        open={visible}
        onOk={handleOk}
        cancelText="cancel"
        okText="confirm"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to redeem it ！！！</p>
      </Modal>
    </div>
  );
};
