import React from "react";
import styles from './Principle.module.css'
import { Typography, Card } from 'antd';

// 开发思想
export const Principle:React.FC = () => {
    const { Title, Paragraph, Text } = Typography;
    const { Meta } = Card;
    return (
        <React.Fragment>
            <div className={styles['page-container']}>
                <Typography>
                    <Title level={3}>一、Introduction to Slingfi</Title>
                    <Paragraph>Slingfi is an open financial strategy tool based on smart contracts, aimed at optimizing investment strategies for cryptocurrency assets while minimizing risk while pursuing rapid profitability. Inspired by the concept of financial markets, using the "Martingale" strategy and utilizing market aggregation effects to execute the strategy, continuously reducing the cost benchmark of the fund pool.</Paragraph>
                    <Paragraph>The operating mechanism of Slingfi is as follows: users have the opportunity to hedge market risks by creating their own fund pools. When the market price reaches the predetermined threshold, other participants can join the fund pool through smart contracts, thereby expanding their positions and reducing average costs. Importantly, during this process, both the initiator and participants of the fund pool do not need to pay any additional fees.</Paragraph>
                    <Paragraph>Slingfi serves as a protective buffer, allowing users to mitigate most market risks and potentially achieve significant financial returns.</Paragraph>
                </Typography>
                <Typography>
                    <Title level={3}>二、Slingfi operating principle</Title>
                    <Paragraph>The application of Martin's strategy in cryptocurrency is as follows:</Paragraph>
                    <div style={{marginLeft:'20px',marginBottom:'10px'}}>
                        <Paragraph>Firstly, invest normally, such as purchasing $10000 of Bitcoin at a price of $10000 per Bitcoin, to obtain 1 Bitcoin.</Paragraph>
                        <Paragraph>The price has dropped to $8000, and your Bitcoin is now worth $8000, losing $2000.</Paragraph>
                        <Paragraph>According to Martin's strategy, if you double your investment and buy 2 Bitcoins, the average cost at this time is 3 Bitcoins, which is $8667.</Paragraph>
                        <Paragraph>If the price drops further to $6000, you can buy 4 more Bitcoins. The average cost now is 7 bitcoins, at $7143.</Paragraph>
                        <Paragraph>As the price drops, you continue to increase your purchase volume, thereby reducing the average cost and achieving the DCA average buying method.</Paragraph>
                        <Paragraph>When the price eventually rebounded, you redeemed Bitcoin at a low price and made a profit.</Paragraph>
                        <Paragraph>The key is to systematically expand the purchasing scale and replenish positions from low points. This allows you to gradually reduce the average cost and profit when prices rise.</Paragraph>
                        <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p21.jpg')} />}
                        >
                            <Meta description="The above figure shows that whenever the price drops, BTC is purchased in multiples of 2 to lower the average price" />
                        </Card>
                        <Paragraph>Slingfi has established an innovative financial model through smart contracts, utilizing the "Martingale" strategy to generate profits. Reduce risk in cryptocurrency investments.</Paragraph>
                        <Paragraph>By analyzing the historical trends of high-quality cryptocurrencies such as BTC and ETH, it is found that regardless of the purchase price, users can achieve profitability by applying the Martingale strategy. This strategy involves doubling investment when cryptocurrencies fall, effectively reducing average entry prices and leveraging price rebounds. The Martingale method has long been used in the financial industry.</Paragraph>
                        <Paragraph>This strategy is suitable for investors with strong financial strength. He is able to withstand higher risks and greater principal losses. For investors with weaker financial resources, it is important to carefully consider using this strategy. Slingfi utilizes the strategy of individual investors clustering together, and anyone can participate in your pool to lower the average price.</Paragraph>
                        <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p22.jpg')} />}
                        >
                        </Card>
                    </div>
                    <Text strong>However, to fully implement the "Martingale" strategy, two conditions need to be met:</Text>
                    <div style={{marginLeft:'20px'}}>
                        <Paragraph>1. Possess unlimited funds.</Paragraph>
                        <Paragraph>2. The market has unlimited capacity to withstand a large amount of buying and selling.</Paragraph>
                    </div>
                    <Paragraph>Considering that it is actually difficult to meet these two conditions, Slingfi has adopted blockchain smart contract technology and implemented the core essence of the "Martingale" strategy in the cryptocurrency field.</Paragraph>
                    <Paragraph>Blockchain smart contracts can automate policy rules through encoding without the need for human intervention. Meanwhile, decentralized blockchain networks provide an open trading platform, making large-scale transactions possible.</Paragraph>
                    <Paragraph>Through this approach, Slingfi overcomes the limitations of implementing the "Martingale" strategy in traditional environments and applies it to the field of cryptocurrency investment, providing users with a relatively secure and reliable investment tool.</Paragraph>
                    <Paragraph>Slingfi utilizes the power of market agglomeration to continuously increase positions during market downturns and strictly implements the "Martingale" strategy. This process aims to bring the average purchase price of cryptocurrencies in the Slingfi fund pool closer to the current market price, ultimately achieving profitability. Please refer to the following diagram 1:</Paragraph>
                    <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p23.jpg')} />}
                        >
                        <Meta description="Figure 1" />
                    </Card>
                    <Paragraph>As shown in the figure, when the market price (blue line) is lower than the average purchase price (red line) of the Slingfi fund pool, Slingfi will start the next layer, allowing users to purchase more Bitcoin to lower the average price. As this process is repeated, the average price line will gradually approach the market price line. Once the market price rebounds and rises above the average purchase price, the Slingfi fund pool can achieve profitability.</Paragraph>
                    <Paragraph>Slingfi utilizes smart contract and oracle technology to obtain the current price of BTC as the cost price. When the price is below the user-defined threshold, new levels are opened for addition, and the amount of addition is limited by a certain multiple. The newly added BTC enters the capital pool at a lower price, reducing the average cost price of BTC in the pool and implementing the "Martingel" strategy. The average cost price of BTC in the pool gradually approaches the current market price, until it exceeds the current price to achieve profitability. Divide and allocate BTC based on cost price and profit price during settlement. The protocol design is fair and transparent, balancing the interests of all parties involved while controlling risks. In summary, Slingfi has implemented the Martingale strategy through smart contract automation, ensuring the fairness of the mechanism through the reliable authentication and data transparency of blockchain. Participants can benefit from fluctuations in BTC prices while maintaining controllable</Paragraph>
                    <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p24.jpg')} />}
                        >
                        <Meta description="Figure 2" />
                    </Card>
                </Typography>
                <Typography style={{marginTop:'10px'}}>
                    <Title level={3}>三、Slingfi settlement rules</Title>
                    <Paragraph>1.When settling, allocate BTC based on the initial investment cost of participants to ensure they do not incur losses.</Paragraph>
                    <Paragraph>2.Then calculate the total profit of the Pool, and based on the total profit, calculate the profit that each participating user can distribute.</Paragraph>
                    <Paragraph>3.To motivate users to continue joining, a portion of the profits after settlement will be distributed to the last group of users to join.</Paragraph>
                    <Paragraph>4.Personal cost: Cost settlement quantity=added price * added quantity/settlement price</Paragraph>
                    <Paragraph>5.Personal profit: Profit distribution quantity=(1- profit sharing rate) * Pool total profit * (personal cost/total cost)</Paragraph>
                    <Paragraph>6.Additional profit for the last layer of users: Quantity of additional profit=Profit sharing rate * Poo total profit * (personal cost/total cost of the last layer)</Paragraph>
                    <Paragraph>7.For example, if a participant purchases 1BTC for $60000 and settles for $10000, they will receive 6BTC, which is equivalent to the initial $60000 investment. If the total profit of the Pool is $10000 and the profit sharing rate of the Pool is 50%, the profit of $5000 will be allocated to all non last tier users based on the proportion of personal costs invested to total costs, and the remaining $5000 will be allocated to last tier users.</Paragraph>
                    <Paragraph>8.After settlement, the total number of individual redemptions=cost settlement quantity+profit distribution quantity+additional profit quantity. By setting a preset hierarchical capacity limit, the profit distribution range is controlled to avoid malicious manipulation, reflecting the intelligence and fairness of the protocol.</Paragraph>
                    <Text strong>The profit distribution mechanism of Slingfi summarizes the following two key objectives:</Text>
                    <div style={{marginLeft:'20px',marginBottom:'10px'}}>
                        <Paragraph>For pool creators, they can establish a pool using cryptocurrency purchased at any time and price. If the value of currency rises, holding returns can be obtained. If it falls, market forces can be utilized to significantly reduce the average cost of holding currency, without the need for additional investment to obtain more currency, effectively reducing investment risks. This will transform high-risk cryptocurrency investments into highly reliable financial investment choices.</Paragraph>
                        <Paragraph>For pool participants, they can freely choose to join any pool. If the pool reaches its target price, it can earn considerable profits. If the price drops after joining, just like the creator, they can obtain more currency in the subsequent market, ensuring that the cash value is not lost. This is a very attractive investment opportunity.</Paragraph>
                        <Paragraph>Slingfi achieves a win-win situation for creators and participants through intelligent design, transforming the high risk of cryptocurrency investment into a controllable investment approach.</Paragraph>
                    </div>
                    <Paragraph>In the Slingfi protocol, users can redeem their encrypted assets at any time in most cases.</Paragraph>
                    <Paragraph>After a pool is successfully created, it will go through two states: "in progress" and "settlement completed":</Paragraph>
                    <div style={{marginLeft:'20px',marginBottom:'10px'}}>
                        <Paragraph>1.In the "in progress" state, users can exit the pool and redeem assets at any time.</Paragraph>
                        <Paragraph>2.Once the pool reaches the target price and triggers settlement, it enters the "settlement completed" state. At this point, the user needs to wait for the settlement to be completed before allocating assets according to the rules.</Paragraph>
                    </div>
                    <Paragraph>This design balances asset liquidity and lock in returns:In the "in progress" state, assets can be withdrawn at any time to ensure liquidity. Lock in assets to ensure profit distribution after reaching the target price.</Paragraph>
                    <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p25.jpg')} />}
                        >
                        <Meta description="Figure 3. In progress status" />
                    </Card>
                    <Paragraph>1.In the "in progress" state, participants at all levels except for the last level can redeem their encrypted assets.</Paragraph>
                    <Paragraph>2.Participants in the final layer can choose to wait for the pool to reach its target price or continue to decline before redeeming assets.</Paragraph>
                    <Paragraph>3.It is important to note that if the user chooses to redeem in the "in progress" state, they can only redeem the initial amount invested and waive the right to subsequent returns.</Paragraph>
                    <Paragraph>4.As long as the pool is still in progress, any redemption behavior will lower the average price of the pool, which is beneficial for users who choose to continue participating.</Paragraph>
                    <Card
                            hoverable={false}
                            style={{ width: '100%' }}
                            cover={<img alt="example" src={require('../image/principleImg/p26.jpg')} />}
                        >
                        <Meta description="Figure 4. Settlement completed status" />
                    </Card>
                    <Paragraph>1.When the current price exceeds the target price of the pool, the pool changes from "in progress" to "settlement completed" status.</Paragraph>
                    <Paragraph>2.In the "settlement completed" state, all users can redeem their invested encrypted assets. (Redemption quantity shall be calculated according to the above formula)</Paragraph>
                    <Paragraph>3.The mechanism of Slingfi ensures the flexibility of user investment, which is not affected by time, price, or identity. This is similar to a bank's current deposit account, but the return potential it provides far exceeds that of traditional deposits.</Paragraph>
                </Typography>
                <Typography>
                    <Text strong>The goal of Slingfi is to improve the reliability of cryptocurrency investments and promote the transformation of mainstream cryptocurrencies such as Bitcoin and Ethereum into value storage assets. Only by achieving this can cryptocurrencies gain wider recognition. Slingfi is not only a DeFi product, but also represents a movement against market manipulators. We no longer tolerate their unscrupulous market manipulation, but rather gather the power of the market to make fair judgments and make malicious manipulators pay a heavy price.</Text>
                </Typography>
            </div>
        </React.Fragment>
    )
}