import React, { useState } from "react";
import styles from './NavigationUl.module.css'
// import { Typography } from "antd";

interface NaviProps {
    handleClick:(value:string,code:string) => void
}
export const NavigationUl:React.FC<NaviProps> = ({handleClick}) => {
    const [value,setValue] = useState('0')
    const userGuidData = [
        { name:'领取测试币方式',nameEn:'Method of collecting test coins',code:'0'},
        { name:'SlingFi原理',nameEn:'SlingFi Principle',code:'1'},
        { name:'操作简介',nameEn:'Operation Introduction',code:'2'},
    ]

    return (
        <React.Fragment>
            <div className={styles['navi-box']}>
                {/* 用户指南 */}
                {/* <Typography.Title level={4}>User Guide</Typography.Title> */}

                <div className={styles['navi-ul']}>
                    {
                        userGuidData.map(item => (
                            <span 
                                className={value === item.code ? styles['on'] : ''} 
                                onClick={() => {
                                    setValue(item.code);
                                    handleClick(item.code,item.nameEn);
                                }
                            }>{item.nameEn}</span>
                        ))
                    }
                    {/* <span className={styles['on']}>领取测试币方式</span>
                    <span>SlingFi原理</span>
                    <span>操作简介</span> */}
                </div>
            </div>
        </React.Fragment>
    )
}