/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import styles from './Portolio.module.css'
import { JoinedPool } from "./JoinedPool";
interface PortolioProps {
    mtsPool?: string
}
export const Portolio:React.FC<PortolioProps> = ({mtsPool}) =>{
    return (
        // <Header></Header>
        <div className={styles['page-container']}>
            <div className={styles['page-inner']}>
                <div style={{marginTop:'20px'}}>
                    <JoinedPool key='joinedlist' mtsPool={mtsPool}/>
                </div>
            </div>
        </div>
    )
}