import { gql } from "@apollo/client";

export const POST_LayerUserData = gql`
    mutation postLayerUserData($layer_pda:String!,$user_pda:String!,$mts_pool_account:String!,$mint_token:String!,$user_pubkey:String!,$user_index:Int!){ 
        postLayerUserData(layer_pda:$layer_pda, user_pda:$user_pda, mts_pool_account:$mts_pool_account,mint_token:$mint_token,user_pubkey:$user_pubkey,user_index:$user_index){ 
            layer_pda
            user_pda
            mts_pool_account
            mint_token
            user_pubkey
            user_index
        }
    }
`;
export const GET_User_Pdas = gql`
  query getUserPdas($publicKey:String!, $mts_pool_account: String!){ 
    getUserPdas(user_pubkey:$publicKey, mts_pool_account: $mts_pool_account) { 
        layer_pda 
        user_pda
        mts_pool_account
        user_pubkey
        user_index
        mint_token
        mint_token_name
        redeem_state
    }
}
`;
export const POST_PAItem_On_Header = gql`
    mutation postPAItemOnHeader($headerId:ID!,$mts_pool_acount:String!,$layer_pda:String!,$layer_index:String!){
        postPAItemOnHeader(headerId:$headerId, mts_pool_account:$mts_pool_acount, layer_pda:$layer_pda, layer_index:$layer_index) {
        id
        mts_pool_account
        layer_pda
        layer_index
        layer_state    
        }
    }
`;
export const Get_Layer_Chart_Data = gql
`query getLayerChartData($mts_pool_account:String!){
    getLayerChartData(mts_pool_account:$mts_pool_account){
        mts_pool_account
        layer_no
        layer_pda
        invested_pect
        joined_amt
        layer_volume
        user_quantity
        total_cost
        start_price
    }
  }`

