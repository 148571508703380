import React from "react";
import styles from './Payment.module.css';
import {Image} from 'antd'

export const Payment:React.FC = () => {
    return (
        <>
            <div className={styles['page-container']}>
                <div>
                    <h3>一、Collect test coins</h3>
                    <div className={styles['paragraph']}>
                    To receive test coins through Discord, first join the Discord community of Slings through the link below
                        <p>Join Link: <span className={styles.on} onClick={() => window.open('https://discord.gg/aWDRPHua')}>https://discord.gg/aWDRPHua</span></p>
                        <div>
                            <p>After entering the community, go to Facebook to receive the test coins for 1000UP by entering:/faucet wallet address</p>
                            <div style={{display:'flex'}}>
                                <Image src={require('../image/faucet.png')} height={'300px'}/>
                                <Image src={require('../image/pubkey.png')}height={'300px'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles['paragraph-box']}>
                    <h3>二、Claim S0L</h3>
                    <p className={styles['paragraph']}>
                        Claim link:<span className={styles.on} onClick={() => window.open('https://faucet.solana.com/')}>https://faucet.solana.com/</span>
                    </p>
                    <div>
                        <p>Enter the corresponding publickey and quantity, click confirm Airport, and wait for the prompt to succeed to obtain the corresponding quantity of sol</p>
                        <p style={{color:'red'}}> (Note: Be sure to choose a development network)</p>
                        <Image src={require('../image/sol.png')} height={'400px'}/>
                    </div>
                </div>
            </div>
        </>
    )
}