/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import style from "./Join.module.css";
import { Row, Col, message, Alert, Spin, Popover } from "antd";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import CustomModal from "./customModal/customModal";
import TableModal from "./customModal/tableModal";
import LineChart from "./LineChart";
// import { ChartData, ChartOptions } from 'chart.js';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  // Connection,
  PublicKey,
  SystemProgram,
  Keypair,
} from "@solana/web3.js";
import {
  BN,
  Program,
  setProvider,
  AnchorProvider,
} from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  connection,
  programId,
  IDL,
  getErrorInfo,
  FloatToBn,
  createTokenAcount,
  base58Slice,
  getUsdtPrice,
} from "../../lib/Common";
import logo from "../../assets/logo.jpg";
import { useMutation, useQuery } from "@apollo/client";
import {
  POST_LayerUserData,
  POST_PAItem_On_Header,
  Get_Layer_Chart_Data,
} from "../../lib/joinApi";
import { useSelector } from "../../redux/hooks";

interface layerDataType {
  start_price: string;
  layer_no: string;
  joined_amt: string;
  layer_volume: string;
}
export const Join: React.FC = () => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const antIcon = (
    <LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />
  );
  const navigate = useNavigate();
  const [ispinNext, setIspinNext] = useState(false);
  const [ispinSettle, setIspinSettle] = useState(false);
  // const [ispinRedeem,setIspinRedeem] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableModalVisible, setIsTableModalVisible] = useState(false);
  const [maxMtsLayerAcount, setMtsLayerAcount] = useState(0);
  const [curentVol, setCrentVol] = useState(0);
  const [layerData, setLayerData] = useState<layerDataType[]>([]);
  // const [weightedPrice,setWeightedPrice] = useState(''); //加权价格
  const [riceUsd, setPriceUsd] = useState(""); //真实价格

  const { search } = useLocation();

  const queryParam: any = queryString.parse(search);

  const mts_pool_account = queryParam.mts_pool_account;
  const getLayerChart = useQuery(Get_Layer_Chart_Data, {
    variables: { mts_pool_account },
  });
  console.log("层级数据-----", getLayerChart, mts_pool_account);

  const [layerUseData] = useMutation(POST_LayerUserData);
  const [saveLayerData] = useMutation(POST_PAItem_On_Header);
  console.log(queryParam);

  // const {publicKey} = useWallet();
  const publicKey = useSelector((state) => state.publicKey);

  const anchorWallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, anchorWallet as any, {});
  setProvider(provider);
  const program = new Program(IDL, programId);

  // const showModal = () => {
  //     setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //     setIsModalVisible(false);
  // };

  const getPrice = async () => {
    const price = await getUsdtPrice("JUP");
    console.log(price);
    setPriceUsd(price);
  };
  useEffect(() => {
    getPrice();
  }, []);
  useEffect(() => {
    const mtsLayerAccountFun = async () => {
      if (!queryParam.layer_account) {
        message.error("layer_account is empty!");
        return;
      }
      const mts_layer_account: any =
        await program.account.mtsPoolLayerAccount.fetch(
          new PublicKey(queryParam.layer_account)
        );
      console.log("mts_layer_account---------------------", mts_layer_account);
      for (let k in mts_layer_account) {
        console.log(k + ":" + parseFloat(mts_layer_account[k]));
      }
      setMtsLayerAcount(parseFloat(mts_layer_account.layerVol));
      setCrentVol(parseFloat(mts_layer_account.curlAmt));
    };
    mtsLayerAccountFun();
  }, [search]);

  const handleConfirm = async (value, usdPrice) => {
    // 1.接口获取start_price,提示：当前价格必须小于等于层级开启价格  （加入模块价格检查）

    // 2.层级开启价格：当前层级(start_price * (100 - fall_pect))/100 （创建下一层级价格检查）

    if (!publicKey) return message.error("Wallet not connected！");
    if (layerData.length === 0) {
      message.error(
        "There is currently no hierarchical data in the current pool"
      );
      setIsModalVisible(false);
      return;
    }
    const index = layerData.length - 1;
    console.log(riceUsd, layerData[index].start_price);
    // 真实价格必须小于层级开始价格
    // if (parseFloat(riceUsd) > parseFloat(layerData[index].start_price)) {

    //   message.warning(
    //     "The current price must be less than or equal to the level opening price,Please try again later！！！"
    //   );
    //   setIsModalVisible(false);
    //   return;
    // }

    try {
      const create_token_acount = await createTokenAcount(
        publicKey,
        queryParam.mint_token
      );
      if (!create_token_acount) return;
      const latestBlockhash = await connection.getLatestBlockhash("finalized");
      let unique_id_pubkey = Keypair.generate().publicKey;
      const [layer_user_pda] = await PublicKey.findProgramAddressSync(
        [unique_id_pubkey.toBuffer()],
        programId
      );
      const tokenProgramId = TOKEN_PROGRAM_ID.toBase58();
      // 加入池子参数
      const join_context = {
        user: publicKey?.toString(),
        mtsPool: queryParam.mts_pool_account,
        layerUserPda: layer_user_pda,
        mintToken: queryParam.mint_token,
        depositTokenAccount: create_token_acount,
        poolTokenAccount: queryParam.pool_token_account,
        layerAccount: queryParam.layer_account,
        tokenProgram: tokenProgramId,
        systemProgram: SystemProgram.programId,
      };

      await JoinPool(
        join_context,
        value,
        usdPrice,
        latestBlockhash,
        unique_id_pubkey
      );

      const layer_pda = queryParam.layer_account;
      const user_pda = layer_user_pda;
      // const mts_pool_account = queryParam.mts_pool_account;
      const user_pubkey = publicKey?.toString();
      const user_index = 2;
      const mint_token = queryParam.mint_token;

      await layerUseData({
        variables: {
          layer_pda,
          user_pda,
          mts_pool_account,
          user_pubkey,
          user_index,
          mint_token,
        },
      });

      setIsModalVisible(false);
      message.info("Joined Pool Success!");
      setTimeout(() => {
        navigate("/PoolList");
      }, 1000);
    } catch (err: any) {
      if (err?.InstructionError && err?.InstructionError.length) {
        message.error(getErrorInfo(err?.InstructionError[1]?.Custom));
      } else {
        message.error(err.toString());
      }
      setIsModalVisible(false);
    }
  };
  const tableHandleCancel = () => {
    setIsTableModalVisible(false);
  };
  // 加入池子
  const JoinPool = async (
    join_context,
    value,
    usdPrice,
    latestBlockhash,
    unique_id_pubkey
  ) => {
    for (let k in join_context) {
      console.log(k + ":" + join_context[k]);
    }
    console.log("program----------", value, usdPrice);
    const valueDec = FloatToBn(value, parseInt(queryParam.decimals));
    const usdPriceDec = FloatToBn(usdPrice, parseInt(queryParam.decimals));
    // Join Price -->value
    const joinpool_tx = await program.methods
      .joinMtspool(valueDec, unique_id_pubkey, usdPriceDec)
      .accounts(join_context)
      .rpc({ skipPreflight: true });

    console.log(`Use 'solana confirm -v ${joinpool_tx}' to see the logs`);

    await connection.confirmTransaction({
      signature: joinpool_tx,
      blockhash: latestBlockhash.blockhash,
      lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
    });
    // console.log(`https://explorer.solana.com/init_tx/${tx}?cluster=devnet`);
    const mtsPool_data_af = await program.account.mtsPoolData.fetch(
      queryParam.mts_pool_account
    );

    console.log("After join, pool token summary amount is: ", mtsPool_data_af);

    const first_layer_data = await program.account.mtsPoolLayerAccount.fetch(
      queryParam.layer_account
    );
    console.log(first_layer_data);
  };
  // 创建下一层级
  const CreateNextLayer = async () => {
    if (!publicKey) return;
    /**当前价格必须达到层级开启价格才可创建 start_price = last layer.start_price *(100 - mts_pool.fall_pect) as u64 / 100
     * @param layerData[index] 取当前层级的层级价格
     * @param fallPect 下跌率
     * @param riceUsd 真实价格
     * */
    const index = layerData.length - 1;
    const curentLayerPrice =
      (parseFloat(layerData[index].start_price) * (100 - queryParam.fallPect)) /
      100; //层级检查的价格
    if (parseFloat(riceUsd) < curentLayerPrice) {
      message.warning(
        "The current price has not reached the level of opening price，Please try again later！"
      );
      return;
    }

    setIspinNext(true);
    // const create_token_acount = await createTokenAcount(publicKey,queryParam.mint_token);
    try {
      await CreateNextLayerEvent();
      setIspinNext(false);
      message.success("Creation completed!");
      setTimeout(() => {
        navigate("/PoolList");
      }, 2000);
    } catch (err: any) {
      if (err?.InstructionError && err?.InstructionError.length) {
        message.error(getErrorInfo(err?.InstructionError[1]?.Custom));
      } else {
        message.error(err.toString());
      }
      setIspinNext(false);
    }

    // console.log(`https://explorer.solana.com/init_tx/${tx}?cluster=devnet`);
    // const mtsPool_data_af = await program.account.mtsPoolData.fetch(
    //     queryParam.mts_pool_account
    // );

    // console.log( "After join, pool token summary amount is: ", mtsPool_data_af);

    // const first_layer_data = await program.account.mtsPoolLayerAccount.fetch(
    //     queryParam.layer_account
    // );
    // console.log(first_layer_data);
  };

  const CreateNextLayerEvent = async () => {
    console.log(queryParam.mts_pool_account, "0--------");

    const latestBlockhash = await connection.getLatestBlockhash("finalized");
    let unique_id_pubkey = Keypair.generate().publicKey;
    const [next_layer_pda] = await PublicKey.findProgramAddressSync(
      [
        unique_id_pubkey.toBuffer(),
        new PublicKey(queryParam.mts_pool_account).toBuffer(),
      ],
      programId
    );

    // const tokenProgramId = TOKEN_PROGRAM_ID.toBase58();
    const create_next_layer_context = {
      creator: new PublicKey(publicKey),
      lastLayer: queryParam.layer_account, //pool_account-->layer_pdas取最后一个account
      nextLayer: next_layer_pda,
      mtsPool: queryParam.mts_pool_account,
      systemProgram: SystemProgram.programId,
    };

    const current_price = new BN(parseFloat(riceUsd));

    for (let k in create_next_layer_context) {
      console.log(k + ":" + create_next_layer_context[k]);
    }

    const joinpool_tx = await program.methods
      .createNextLayer(unique_id_pubkey, current_price)
      .accounts(create_next_layer_context)
      .rpc({ skipPreflight: true });

    console.log(`Use 'solana confirm -v ${joinpool_tx}' to see the logs`);

    await connection.confirmTransaction({
      signature: joinpool_tx,
      blockhash: latestBlockhash.blockhash,
      lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
    });

    // 保存层级数据
    const nextLayer = parseFloat(queryParam.Curent_Layer) + 1;
    const mts_pool_acount = queryParam.mts_pool_account;
    const layer_pda = next_layer_pda;
    const layer_index = nextLayer.toString();
    const headerId = queryParam.id;
    saveLayerData({
      variables: { mts_pool_acount, layer_pda, layer_index, headerId },
    }).then((res) => {
      console.log("保存层级数据---------", res);
    });
  };

  // 复制
  const CopyClick = (e) => {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        message.success("Copied!");
      })
      .catch(() => {
        message.error("Copy failed!");
      });
  };

  // total cost 计算
  const totalCost = (t) => {
    let decimals = parseInt(queryParam.decimals) + 2;
    return parseFloat(t) / Math.pow(10, decimals);
  };
  // average Price 计算
  const averagePrice = () => {
    const totalcost = totalCost(queryParam.totalCost);
    console.log("计算--", totalcost);
    console.log(queryParam.tokenAmountSum);
    return (
      totalcost /
      (parseFloat(queryParam.tokenAmountSum) /
        Math.pow(10, queryParam.decimals))
    );
  };
  // 结算
  const onSettlement = async () => {
    setIspinSettle(true);
    try {
      const context = {
        signer: publicKey,
        mtsPool: new PublicKey(queryParam.mts_pool_account),
        permitTokens: new PublicKey(queryParam.mint_token),
      };
      for(let k in context) {
        console.log('结算参数--', k + '=' + context[k].toString())
      }
      const poolState = { settled: {} };
      const settlePrice = new BN(3000);
      const transaction = await program.methods
        .changeMtspoolState(poolState, settlePrice)
        .accounts(context)
        .rpc();
      message.info(transaction.toString());
      setIspinSettle(false);
    } catch (err: any) {
      if (err?.InstructionError && err?.InstructionError.length) {
        message.error(getErrorInfo(err?.InstructionError[1]?.Custom));
      } else {
        message.error(err.toString());
      }
      setIspinSettle(false);
    }
    // window.location(`https://explorer.solana.com/tx/${transaction}?cluster=devnet`)
  };
  const LayerComponent = () => {
    useEffect(() => {
      console.log("层级-sss---", getLayerChart);

      const layerD = getLayerChart?.data?.getLayerChartData ?? [];

      if (layerD.length !== 0) {
        setLayerData(layerD);
      }
    }, [getLayerChart]);

    const content = (row) => {
      console.log("row-----", row);

      return (
        <div>
          <p>Invested Pect: {row.invested_pect}</p>
          <p>Joined Amt: {row.joined_amt}</p>
          <p>Layer Volume: {row.layer_volume}</p>
          <p>Start Price: {row.start_price}</p>
          <p>Total Cost: {row.total_cost}</p>
          <p>User Quantity: {row.user_quantity}</p>
        </div>
      );
    };
    return (
      <>
        <div className={style["second-left"]}>
          <div className={style["second-title"]}>
            <span>Layer No</span>
            <span>Invest Progress</span>
            <span>Joined/Total</span>
          </div>
          <div className={style["secend-list"]}>
            {layerData.map((item, index) => (
              <Popover
                placement="leftTop"
                content={content(item)}
                title={"L" + item.layer_no}
              >
                <div className={style["li"]} key={index}>
                  <div className={style["lay"]}>L{item.layer_no}</div>
                  <div className={style["shap"]}>
                    <span>
                      {(
                        (parseFloat(item.joined_amt) /
                          parseFloat(item.layer_volume)) *
                        100
                      ).toFixed(2)}
                      %
                    </span>
                    <div
                      className={style["progress"]}
                      style={{
                        width:
                          "calc(" +
                          (
                            (parseFloat(item.joined_amt) /
                              parseFloat(item.layer_volume)) *
                            100
                          ).toFixed(2) +
                          "% + 2px)",
                      }}
                    ></div>
                  </div>
                  <div className={style["shapeTitle"]}>
                    <span>{item.joined_amt + "/" + item.layer_volume}</span>
                  </div>
                </div>
              </Popover>
            ))}
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={style.inner}>
      <div className={style["first_part"]}>
        <div className={style["first_part_header"]}>
          <div className={style["type"]}>
            <img
              style={{ marginRight: "16px", borderRadius: "50%" }}
              src={logo}
              alt=""
              width={62}
              height={62}
            />
            <span>{queryParam.mint_token_name}</span>
            {/* <UploadOutlined style={{marginLeft: '16px'}}/> */}
          </div>
          <div className={style["creator"]}>
            <div>
              <span>Creator : </span>
              <span>{base58Slice(queryParam.creator_account)}</span>
              <CopyOutlined
                style={{ marginLeft: "5px" }}
                onClick={() => CopyClick(queryParam.creator_account)}
              />
            </div>
            <div>
              <span>Pool ID : </span>
              <span>{base58Slice(queryParam.Mts_POOL_Account)}</span>
              <CopyOutlined
                style={{ marginLeft: "5px" }}
                onClick={() => CopyClick(queryParam.Mts_POOL_Account)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style["second_part"]}>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col span={8}>
            <LayerComponent />
          </Col>
          <Col span={14}>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col className={style["colItem"]}>
                <span>Current Layer</span>
                <p>
                  <span>{queryParam.Curent_Layer}</span>
                  {/* <span style={{fontSize: '14px', color: 'rgb(183, 55, 55)', marginLeft: '10px'}}>1.2098/Unit</span> */}
                </p>
              </Col>
              <Col className={style["colItem"]}>
                <span>Multiple</span>
                <p>
                  <span>{queryParam.multiple}</span>
                  {/* <span style={{fontSize: '14px', color: 'rgb(183, 55, 55)', marginLeft: '10px'}}>0/D/Unit</span> */}
                </p>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Col className={style["colItem"]}>
                <span>Profit Share Percent</span>
                <p>
                  <span>{queryParam.Profit_Share_Percent}</span>
                </p>
              </Col>
              <Col className={style["colItem"]}>
                <span>Goal Profit Ratio</span>
                <p>
                  <span>{queryParam.goalProfRat}%</span>
                </p>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Col className={style["colItem"]}>
                <span>Total Invested Amount</span>
                <p>
                  <span>
                    {parseFloat(queryParam.tokenAmountSum) /
                      Math.pow(10, queryParam.decimals)}
                  </span>
                </p>
              </Col>
              <Col className={style["colItem"]}>
                <span>Total Invested Cost</span>
                <p>
                  <span>{totalCost(queryParam.totalCost)}</span>
                </p>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Col className={style["colItem"]}>
                <span>Average Price</span>
                <p>
                  <span>{averagePrice()}</span>
                </p>
              </Col>
              <Col className={style["colItem"]}>
                <span>Pool Status</span>
                <p
                  className={
                    queryParam.status === "Investing"
                      ? style["green"]
                      : style["red"]
                  }
                >
                  <span>{queryParam.status}</span>
                </p>
              </Col>
            </Row>
            <div className={style["operate-content"]}>
              <div className={style["bottom-right"]}>
                <div onClick={() => onSettlement()} className={style["btn"]}>
                  {!ispinSettle ? null : <Spin indicator={antIcon} />}
                  <span style={{ marginLeft: "8px" }}>Settlement</span>
                </div>
                {/* 当前数量若大于等于最大容量则显示下一层级 ，改成：第一层时默认为创建下一层*/}
                {queryParam.Curent_Layer === "1" ||
                (maxMtsLayerAcount !== 0 && curentVol >= maxMtsLayerAcount) ? (
                  <div onClick={CreateNextLayer} className={style["btn"]}>
                    {!ispinNext ? null : <Spin indicator={antIcon} />}
                    <span style={{ marginLeft: "8px" }}>Create Next Layer</span>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setIsModalVisible(true);
                    }}
                    className={style["btn"]}
                  >
                    <span style={{ marginLeft: "8px" }}>Join Pool</span>
                  </div>
                )}
                {queryParam.Curent_Layer !== "1" ? (
                  <div
                    onClick={() => {
                      setIsTableModalVisible(true);
                    }}
                    className={style["redem"]}
                  >
                    Redeem
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {maxMtsLayerAcount !== 0 && curentVol >= maxMtsLayerAcount ? (
              <Alert
                message="Notice"
                description="Current layer token amount have reached maximum quantity. If you want join this pool, please create next layer at first"
                type="warning"
                showIcon
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
      <div style={{ backgroundColor: "#fff", marginTop: "10px" }}>
        <LineChart />
      </div>
      <CustomModal
        param={queryParam}
        startPrice={
          layerData && layerData.length
            ? layerData[layerData?.length - 1]?.start_price
            : "0"
        }
        key="join"
        riceUsd={riceUsd}
        visible={isModalVisible}
        onOk={handleConfirm}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
      <TableModal
        key="redeem"
        visible={isTableModalVisible}
        mtspool={queryParam.mts_pool_account}
        onClose={tableHandleCancel}
      />
    </div>
  );
};
